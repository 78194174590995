











































































































import { Component, Vue } from "vue-property-decorator";
import { Toast,Dialog } from "vant";
import pageBack from "src/components/page-back";

@Component({
    components:
    {
        pageBack
    }
})
export default class ReportList extends Vue
{   

     /**
     * 左切换
     * @private
     */
    protected leftF()
    {
        this.tipDiv = true;
        (this.$refs.swipeKJ as any).prev();
    }

     /**
     * 右切换
     * @private
     */
    protected rightF()
    {
        this.tipDiv = true;
        (this.$refs.swipeKJ as any).next();
    }

     /**
     * tipDiv
     * @private
     */
    protected tipDiv:boolean = false;
    
     /**
     * 改变tip方法
     * @private
     */
    protected changeTipDiv()
    {
        this.tipDiv = true;
    }
  
    /**
     * 获取报告列表
     * @private
     * @returns void
     */
    private get reportList(): Array<any>
    {
        let reportListStorage = localStorage.getItem("reportList");
        if(reportListStorage)
        {
            return JSON.parse(reportListStorage)
        }

        return [];
    }

    /**
     * 跳转详情
     * @private
     * @returns void
     */
    private onReportDetail(orderNo: any): void
    {
        this.$router.push({name: "reportDetail", query:{orderNo}});
    }

}
