
































































import { Component, Vue, Watch } from "vue-property-decorator";
import { ReportService, UserService } from "src/services";
import { Toast } from "vant";
import { Service } from "src/decorator";
import pageBack from "src/components/page-back";
import patterns from "src/utils/patterns";

@Component({
    components:
    {
        pageBack
    }
})
export default class ReportLogin extends Vue
{

    /**
     * 验证身份信息数据模型
     * @private
     * @returns string
     */
    private modelData: any =
    {
        phone: "",
        cardNo: "",
        code: "",
        isApprove: false
    };

    /**
     * 点击获取验证码等60秒完成后才可以重新点击发送
     * @private
     * @returns boolean
     */
    private isDisabled: boolean = false;

    /**
     * 获取验证码
     * @private
     * @returns string
     */
    private sendCode: string = "获取验证码";

    /**
     * 获取验证码60秒
     * @private
     * @returns string
     */
    private time: number = 60;

    /**
     * 验证是否正确手机号
     * @private
     * @returns void
     */
    private validatorPhone(val: any): any
    {
        return patterns.mobile.test(val);
    }

    /**
     * 跳转隐私
     * @private
     * @returns void
     */
    private goPrivacyFile(): void
    {
        this.$router.push({name: "privacyFile"});
    }

    /**
     * 获取验证码
     * @private
     * @returns void
     */
    private async onGetModifyCode(): Promise<void>
    {
        try
        {
            if(!this.modelData.cardNo)
            {
                Toast({
                    duration: 1000,
                    message: "请输入证件信息"
                });

                return;
            }

            if(!this.modelData.phone || this.modelData.phone.length != 11)
            {
                Toast({
                    duration: 1000,
                    message: "请输入11位手机号"
                });

                return;
            }

            let data =
            {
                smsType: 4,
                phoneNum: this.modelData.phone
            };

            await UserService.instance.smsSend(data);
           
            Toast("验证码发送成功");
            this.isDisabled = true;
            let interval = window.setInterval(() =>
            {
                this.sendCode = `${this.time}s`;
                --this.time;
                if(this.time < 0)
                {
                    this.sendCode = "重新发送";
                    this.time = 60;
                    this.isDisabled = false;
                    window.clearInterval(interval);
                }
            },1000);
        }
        catch(err)
        {
            Toast(err);
        }

    }

    /**
     * 跳转我的报告列表页面
     * @private
     * @returns void
     */
    private async onSave(): Promise<void>
    {
        try
        {
            if(!this.modelData.isApprove)
            {
                this.goPrivacyFile();
                return 
            }
            if(this.verifyInfo())
            {
                let {content: result} = await ReportService.instance.getReportList(this.modelData);

                if(result.data)
                {
                    localStorage.setItem("reportList", JSON.stringify(result.data));
                    this.$router.push({name: "reportList"});
                }
            }
           
        }
        catch(err)
        {
            Toast(JSON.stringify(err));
        }
    }

    /**
     * 验证身份信息查询表单
     * @private
     * @returns void
     */
    private verifyInfo(): boolean
    {
        if(!this.modelData.cardNo)
        {
            Toast({
                duration: 1000,
                message: "请输入体检人身份证信息"
            });

            return false;
        }

        if(!this.modelData.phone || this.modelData.phone.length !== 11)
        {
            Toast({
                duration: 1000,
                message: "请输入正确手机号码"
            });

            return false;
        }

        if(!this.modelData.code || this.modelData.code.length !== 6)
        {
            Toast({
                duration: 1000,
                message: "请输入正确短信验证码"
            });

            return false;
        }

        return true;
    }

    /**
     * 当路由发生变化的时候操作。
     * @protected
     * @param {any} - to 当前路由实例。
     * @returns {void}
     */
    @Watch("$route", {deep: true, immediate: true})
    protected updateOpen(): void
    {
        let agreementStatus = localStorage.getItem("agreementStatus");
        let status = (this.$route.query as any).status;
        if(status == 0 || agreementStatus == "true") {
            this.modelData.isApprove = true;
        } else {
            this.modelData.isApprove = false;
        }
    }

    /**
     * 协议变更
     * @protected
     */
    protected onAgreementChange(value: any): void
    {
        localStorage.setItem("agreementStatus", value)
    }

}
