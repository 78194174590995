





















































































































import { Component, Vue } from "vue-property-decorator";
import { ReportService } from "src/services";
import { Toast } from "vant";
import pageBack from "src/components/page-back";
import { downloadFile } from "src/utils/extends"
import { DesSecretUtils } from "src/utils";
import sportPdf from "./sport-pdf.vue";

@Component({
    components:
    {
        pageBack,
        sportPdf
    }
})
export default class ReportDetail extends Vue
{
    pdfUrl: string = "";
    downloadUrl: string = "";

    /**
     * PDF下载弹框
     * @private
     * @returns boolean
     */
    private showPDFDownloadModel: boolean = false;

    /**
     * 是否显示PDF
     * @private
     * @returns void
     */
    private showPDF: boolean = false;

    /**
     * 显示弹框
     * @private
     * @returns void
     */
    private showPopover: boolean = false;

    getContainer() {
        return document.querySelector('.my-container');
    }

    /**
     * 体检项目默认全部展开
     * @private
     * @returns void
     */
    private activeNames: Array<string> = [];

    /**
     * 体检报告信息
     * @private
     * @returns void
     */
    private reportInfo: any = {};

    /**
     * 订单编号
     * @private
     * @returns void
     */
    private get orderNo(): any
    {
        return this.$route.query.orderNo  || "";
    }

    /**
     * 获取JSON报告
     * @private
     * @returns void
     */
    private async getReportJson(): Promise<void>
    {
        try
        {
            let {content: result} = await ReportService.instance.getReportJson(this.orderNo);
            if(result.data)
            {
                this.reportInfo = result.data;
            }
        }
        catch(err)
        {
            Toast(JSON.stringify(err));
        }
    }

    /**
     * 获取PDF报告
     * @private
     * @returns void
     */
    private async getReportPDF(): Promise<void>
    {
        try
        {
            let {content: result} = await ReportService.instance.getReportPDF(this.orderNo);
            this.downloadUrl = DesSecretUtils.encrypt(result.data.pdfFullPath);
            this.pdfUrl = this.base64ToBlob(DesSecretUtils.encrypt(result.data.pdfBase64), "application/pdf;chartset=UTF-8");
        }
        catch(err)
        {
            Toast(JSON.stringify(err));
        }
    }

    protected base64ToBlob(base64: any, mime: any) {
        mime = mime || "";
        let sliceSize = 1024;
        let byteChars = window.atob(base64);
        let byteArrays = [];

        for (let offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
            let slice = byteChars.slice(offset, offset + sliceSize);

            let byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            let byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        return URL.createObjectURL(new Blob(byteArrays, { type: mime }));
    }

    private async copyPdfUrl(str): Promise<void>
    {
        let save = function (e) {
            e.clipboardData.setData("text/plain", str);
            e.preventDefault();
        };
        let input = document.createElement("input");
        input.value = str;
        document.body.appendChild(input);
        input.select();
        input.setSelectionRange(0, input.value.length), document.execCommand("Copy");
        document.body.removeChild(input);
        Toast({
            message: "复制成功",
            duration: 1000
        });
    }

    /**
     * 组件创建钩子
     * @private
     * @returns void
     */
    private created()
    {
        this.getReportJson();
        this.getReportPDF();
    }

    /**
     * 关闭PDF
     * @private
     * @returns void
     */
    private onClose()
    {
        this.showPDF = false;
    }

    /**
     * 显示PDF
     * @private
     * @returns void
     */
    private onShowPDF()
    {
        this.showPDF = true;
        this.showPopover = false;
    }

    /**
     * 显示PDF
     * @private
     * @returns void
     */
    private onDownload()
    {
        this.showPopover = false;
        this.showPDFDownloadModel = true;
    }

    

}
