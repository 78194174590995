























































import { Component, Vue } from "vue-property-decorator";
import pageBack from "src/components/page-back";

@Component({
    components:
    {
        pageBack
    }
})
export default class OrganizationDetail extends Vue
{

    /**
     * 返回验证身份信息页面
     * @private
     * @returns void
     */
    private async onAgree(): Promise<void>
    {
        this.$router.replace({name: "reportLogin", query: {status: "0"}});
    }
}
